import { applyMiddleware, combineReducers, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';

import authReducer from './reducers/auth';
import authSaga from './sagas/auth';

const rootReducer = combineReducers({
  auth: authReducer
});

function* rootSaga() {
  yield all([
    ...authSaga
  ]);
}

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducer,
  applyMiddleware(
    sagaMiddleware
  )
);

sagaMiddleware.run(rootSaga);

export default store;