import React from 'react';
import BEM from '../lib/BEM';

const bem = new BEM('preview');

export const Preview = () => (
  <div className={ bem.b }>
    <div className={ bem.e('bar') }>
      <div className={ bem.e('button') }></div>
      <div className={ bem.e('button') }></div>
      <div className={ bem.e('button') }></div>
    </div>
    <div className={ bem.e('messages') }>
      <div className={ bem.e('clipped') }></div>
      <div className={ bem.e('message') }>
        <div className={ bem.e('bot') }>
          <div className={ bem.e('avatar') }>
            <img alt="Harvey" src={ require('../logo.svg') } width="28" height="28" style={{ borderRadius: '50%' }}/>
          </div>
          <div className={ bem.e('username') }>Harvey</div>
          <div className={ bem.e('label') }>APP</div>
          <div className={ bem.e('timestamp') }>3:00 PM</div>
        </div>
        <div className={ bem.e('body') }>
          <div className={ bem.e('text') }>
            Hey <span className={ bem.e('link') }>@everyone</span>! We’ve got some great news!<br/>
            These people have submitted all their hours this week!
          </div>
          <div className={ bem.e('users') }>
            <div className={ bem.e('user') }>
              <div className={ bem.e('photo') }>
                <img alt="Avatar" src={ require('../man-1.png') } width="40" height="40" style={{ borderRadius: '50%' }}/>
              </div>
              <div className={ bem.e('hours') }>
                Paul Harper 37.5hrs
              </div>
            </div>
            <div className={ bem.e('user') }>
              <div className={ bem.e('photo') }>
                <img alt="Avatar" src={ require('../woman-1.png') } width="40" height="40" style={{ borderRadius: '50%' }}/>
              </div>
              <div className={ bem.e('hours') }>
                Michelle Vicroy 41hrs
              </div>
            </div>
            <div className={ bem.e('user') }>
              <div className={ bem.e('photo') }>
                <img alt="Avatar" src={ require('../man-2.png') } width="40" height="40" style={{ borderRadius: '50%' }}/>
              </div>
              <div className={ bem.e('hours') }>
                Troy Mattchel 39hrs
              </div>
            </div>
          </div>
          <div>
            Good job! <span className={ bem.e('emoji') } role="img" aria-label="Party">🎉</span> <span className={ bem.e('emoji') } role="img" aria-label="Party">🎉</span>
          </div>
        </div>
      </div>
      <div className={ bem.e('message') }>
        <div className={ bem.e('bot') }>
          <div className={ bem.e('avatar') }>
            <img alt="Harvey" src={ require('../logo.svg') } width="28" height="28" style={{ borderRadius: '50%' }}/>
          </div>
          <div className={ bem.e('username') }>Harvey</div>
          <div className={ bem.e('label') }>APP</div>
          <div className={ bem.e('timestamp') }>3:00 PM</div>
        </div>
        <div className={ bem.e('body') }>
          <div className={ bem.e('text') }>
            Hey <span className={ bem.e('link') }>@everyone</span> nobody's logged their hours this week <span className={ bem.e('emoji') } role="img" aria-label="Face Palm">🤦</span> <span className={ bem.e('emoji') } role="img" aria-label="Face Palm">🤦</span>
          </div>
          <div className={ bem.e('users') }>
            <div className={ bem.e('user') }>
              First one to add their hours gets a cold <span className={ bem.e('emoji') } role="img" aria-label="Beer">🍺</span>
            </div>
          </div>
        </div>
      </div>
      <div className={ bem.e('message') }>
        <div className={ bem.e('bot') }>
          <div className={ bem.e('avatar') }>
            <img alt="Harvey" src={ require('../logo.svg') } width="28" height="28" style={{ borderRadius: '50%' }}/>
          </div>
          <div className={ bem.e('username') }>Harvey</div>
          <div className={ bem.e('label') }>APP</div>
          <div className={ bem.e('timestamp') }>3:00 PM</div>
        </div>
      </div>
    </div>
  </div>
);

export default Preview;