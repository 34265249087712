import React from 'react';
import { Route } from "react-router";

const Page = ({ component: Component, layout: Layout, footer, ...rest }) => {
  return (
    <Route
      { ...rest }
      render={ (props) => (
        <Layout footer={ footer }>
          <Component { ...props }/>
        </Layout>
      ) }
    />
  );
};

export default Page;