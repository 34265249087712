import React from 'react';
import BEM from '../lib/BEM';
import Button from './Button';
import Preview from './Preview';
import copy from '../copy/header';
import Slack from '../constants/Slack';

const bem = new BEM('header');

class Header extends React.Component {
  render() {
    return (
      <div className={ bem.b }>
        <div className="wrapper">
          <div className="container">
            <div className="enemies">
              <div className={ bem.e('aside') }>
                <div className={ bem.e('headline') }>{ copy.headline }</div>
                <div className={ bem.e('strapline') }>{ copy.strapline }</div>
                <div className={ bem.e('actions') }>
                  <Button href={ Slack.signInWithSlack } id={ bem.e('login') } shadow large>{ copy.ctaLabel }</Button>
                </div>
              </div>
              <Preview/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;