export const getCurrentAuthenticatedCustomer = () => {
  return {
    type: 'AUTH_CURRENT_AUTHENTICATED_CUSTOMER_FETCH_BEGIN'
  };
};

export const getCurrentAuthenticatedCustomerResponse = ({ data }) => {
  return {
    type: 'AUTH_CURRENT_AUTHENTICATED_CUSTOMER_FETCH_RESPONSE',
    data
  };
};

export const getCurrentAuthenticatedCustomerEnd = () => {
  return {
    type: 'AUTH_CURRENT_AUTHENTICATED_CUSTOMER_FETCH_END'
  };
};