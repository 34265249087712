import React from 'react';
import BEM from '../lib/BEM';

const bem = new BEM('background');

const Background = (props) => (
  <div className={ bem.b }>
    { props.children }
  </div>
);

export default Background;
