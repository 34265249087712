import React from 'react';
import store from './store';
import Page from './components/Page';
import Root from './components/Root';
import { Provider } from 'react-redux';
import RootLayout from './layouts/Root';
import { BrowserRouter } from 'react-router-dom';

class App extends React.Component {
  render() {
    return (
      <Provider store={ store }>
        <BrowserRouter>
          <div className="App">
            <Page path="/" exact component={ Root } layout={ RootLayout }/>
          </div>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
