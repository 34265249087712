import React from 'react';
import BEM from '../lib/BEM';
import Button from './Button';
import copy from '../copy/navigation';
import Slack from '../constants/Slack';
import { Link } from 'react-router-dom';

const bem = new BEM('navigation');

class Navigation extends React.Component {
  logout() {
    localStorage.removeItem('token');
    window.location.href = '/';
  }

  render() {
    return (
      <div className={ bem.b }>
        <div className="wrapper">
          <div className="container">
            <div className="enemies">
              <Link to="/" className={ bem.e('logo') }>
                <img src={ require('../logo.svg') } alt="Harvey" width="44" height="44" />
              </Link>
              { this.props.login ? (
                <Button outline href={ Slack.signInWithSlack } id={ bem.e('login') }>{ copy.ctaLabel }</Button>
              ) : null }
              { this.props.logout ? (
                <div className={ bem.e('logout') } onClick={ () => this.logout() }>Logout</div>
              ) : null }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Navigation;