import axios from 'axios';

const baseURL = process.env.REACT_APP_API_ROOT;

export const unauthenticated = axios.create({ baseURL });

export const authenticated = axios.create({
  baseURL,
  headers: {
    'Authorization': 'bearer ' + localStorage.getItem('token')
  }
});