import React from 'react';
import Intro from './Intro';
import Footer from './Footer';
import Header from './Header';
import Background from './Background';
import Navigation from './Navigation';

class Root extends React.Component {

  render() {
    return (
      <div className="root">
        <Background>
          <Navigation login={ true } />
          <Header/>
        </Background>
        <Intro/>
        <Footer/>
      </div>
    );
  }
}

export default Root;
