export default (state = {}, action) => {
  switch (action.type) {
    case 'AUTH_CURRENT_AUTHENTICATED_CUSTOMER_FETCH_BEGIN':
      return {
        loading: true
      };
    case 'AUTH_CURRENT_AUTHENTICATED_CUSTOMER_FETCH_END':
      return {
        ...state,
        loading: false
      };
    case 'AUTH_CURRENT_AUTHENTICATED_CUSTOMER_FETCH_RESPONSE':
      return {
        ...state,
        customer: {
          ...action.data
        }
      }
    default:
      return state;
  }
};
