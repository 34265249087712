import React from 'react';
import BEM from '../lib/BEM';
import Button from './Button';
import copy from '../copy/intro';
import Slack from '../constants/Slack';

const bem = new BEM('intro');

class Intro extends React.Component {
  render() {
    return (
      <div className="wrapper">
        <div className="container">
          <div className={ bem.b }>
            <div className={ bem.e('headline') }>{ copy.headline }</div>
            <div className={ bem.e('strapline') }>{ copy.strapline }</div>
            <div className="center">
              <Button href={ Slack.signInWithSlack } id={ bem.e('login') } large shadow>{ copy.ctaLabel }</Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Intro;