export default {
  copy: '\u00A9 2019 Invoke Media. All rights reserved.',
  email: 'info@harvey.works',
  networks: [
    {
      url: 'https://www.producthunt.com/posts/harvey',
      label: 'Product Hunt'
    }, {
      url: 'https://github.com/invokemedia/harvey-serverless',
      label: 'Fork on GitHub'
    }, {
      url: 'https://invokedigital.co',
      label: 'About Invoke'
    }
  ]
};