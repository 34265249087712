import { takeLatest, put } from 'redux-saga/effects';

import {
  getCurrentAuthenticatedCustomerResponse,
  getCurrentAuthenticatedCustomerEnd
} from '../actions/auth';

import { authenticated } from '../../lib/API';

function* getCurrentAuthenticatedCustomerBegin(action) {
  try {
    const { data } = yield authenticated.get('customer');
    yield put(getCurrentAuthenticatedCustomerResponse(data));
  }
  catch(e) {
    console.log(e)
  }
  finally {
    yield put(getCurrentAuthenticatedCustomerEnd());
  }
}

export default [
  takeLatest('AUTH_CURRENT_AUTHENTICATED_CUSTOMER_FETCH_BEGIN', getCurrentAuthenticatedCustomerBegin),
  // takeLatest(Actions.algoliaUsersFetchBegin, usersFetchBegin)
];