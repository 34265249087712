import React from 'react';
import BEM from '../lib/BEM';
import ClassNames from 'classnames';

const bem = new BEM('button');

const Button = (props) => {
  let El = (props) => (<button { ...props }>{ props.children }</button>);

  if (props.href) {
    El = (props) => (<a { ...props }>{ props.children }</a>);
  }

  return (
    <El
      onClick={ props.onClick }
      linkTo={ props.linkTo }
      href={ props.href }
      id={ props.id }
      className={ ClassNames(
        bem.b,
        {
          [bem.m('secondary')]: props.secondary,
          [bem.m(props.shadow)]: props.shadow,
          [bem.m('outline')]: props.outline,
          [bem.m('large')]: props.large
        }
      ) }
    >
      { props.children }
    </El>
  );
}

export default Button;
