import React from 'react';
import BEM from '../lib/BEM';
import copy from '../copy/footer';

class Footer extends React.Component {
  render() {
    const bem = new BEM('footer');
    return (
      <footer className={ bem.b }>
        <div className="wrapper">
          <div className="container">
            <div className={ bem.e('enemies') }>
              <div>
                <ul className={ bem.e('links') }>
                  { copy.networks.map((n, i) => (
                      <li className={ bem.e('link') } key={ i }>
                        <a className={ bem.e('navigation') } href={ n.url }>{ n.label }</a>
                      </li>
                    )) }
                </ul>
              </div>
              <div>
                <div className={ bem.e('copy') }>{ copy.copy }</div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
